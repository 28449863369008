@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
$pageWidth: 209mm;
$pageHeight: 296mm;
$pageColor: white;
$unitSpacing: 4px;
$fontSizeLarge: 24px;
$fontSizeLarger: 28px;
$fontSizeMedium: 16px;
$fontSizeMediumLarge: 20px;
$bgColor: rgb(211, 211, 211);
$fontFamily: "Roboto", sans-serif;
$themeColor: darkblue;
$accentColor: darkslategray;
$letterSpacingLarge: 0.7px;
$letterSpacingMedium: 0.4px;

section.cv-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;

  section.cv-container {
    padding: 4 * $unitSpacing;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $bgColor;
    min-width: $pageWidth + $unitSpacing;
    min-height: $pageHeight + $unitSpacing;

    section.page {
      width: $pageWidth;
      height: $pageHeight;
      background-color: $pageColor;
      margin-bottom: 4 * $unitSpacing;
      position: relative;
      page-break-after: always;

      div.heading-with-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        div.line {
          background-color: $themeColor;
          height: 2px;
          &.left {
            width: 4 * $unitSpacing;
            margin-right: $unitSpacing;
            color: $themeColor;
          }
          &.right {
            flex-grow: 1;
            margin-left: $unitSpacing;
            color: $themeColor;
          }
        }
        div.heading {
          color: $themeColor;
          font-size: $fontSizeLarge;
          font-weight: bold;
          font-family: $fontFamily;
          letter-spacing: $letterSpacingLarge;
        }
      }

      div.two-col-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 4px;
      }

      div.content {
        margin: 2 * $unitSpacing 0;
        p,
        ul {
          font-family: $fontFamily;
          font-size: $fontSizeMedium;
          margin: 0;
        }
        ul {
          padding-left: $unitSpacing * 4;
        }
        p.sub-heading {
          color: $accentColor;
          font-weight: 500;
          letter-spacing: $letterSpacingMedium;
        }
        span {
          font-family: $fontFamily;
          border: 2px solid $accentColor;
          padding: 2px 2 * $unitSpacing;
          border-radius: 4 * $unitSpacing;
          display: inline-block;
          margin-top: $unitSpacing;
        }
      }
      section.intro {
        position: absolute;
        top: 8 * $unitSpacing;
        left: 8 * $unitSpacing;
        right: 8 * $unitSpacing;
        display: flex;
        flex-direction: row;
        padding: 0;
        img.photo {
          height: 40 * $unitSpacing;
          object-fit: contain;
          border-radius: $unitSpacing;
        }
        div.name-and-summary {
          padding-left: 2 * $unitSpacing;
          display: flex;
          flex-direction: column;
          justify-content: center;
          p.name {
            font-size: $fontSizeLarger;
            font-weight: bold;
            letter-spacing: $letterSpacingLarge;
            line-height: $fontSizeLarger;
            font-family: $fontFamily;
            color: $themeColor;
            margin: 0;
          }
          p.title {
            font-family: $fontFamily;
            font-weight: 500;
            color: $accentColor;
            margin: 2px 0 6px;
            font-size: $fontSizeMediumLarge;
          }
          p.summary {
            font-size: $fontSizeMedium;
            font-family: $fontFamily;
            margin: 0;
          }
        }
      }
      section.details {
        background-color: $themeColor;
        position: absolute;
        top: 50 * $unitSpacing;
        left: 8 * $unitSpacing;
        right: 8 * $unitSpacing;
        padding: 4 * $unitSpacing;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2 * $unitSpacing;
        align-items: center;
        border-radius: $unitSpacing;
        span {
          color: white;
          font-family: $fontFamily;
          font-weight: 500;
          padding-left: 24 * $unitSpacing;
        }
        a.no-decoration {
          text-decoration: none;
          color: inherit;
          font-size: inherit;
        }
      }
      section.experience {
        position: absolute;
        top: 74 * $unitSpacing;
        right: 8 * $unitSpacing;
        left: 8 * $unitSpacing;
      }
      section.projects {
        position: absolute;
        top: 146 * $unitSpacing;
        right: 8 * $unitSpacing;
        left: 8 * $unitSpacing;
      }
      section.participation {
        position: absolute;
        top: 220 * $unitSpacing;
        right: 8 * $unitSpacing;
        left: 8 * $unitSpacing;
      }
      section.education {
        position: absolute;
        top: 8 * $unitSpacing;
        right: 8 * $unitSpacing;
        left: 8 * $unitSpacing;
      }
      section.training {
        position: absolute;
        top: 68 * $unitSpacing;
        left: 8 * $unitSpacing;
        right: 8 * $unitSpacing;
      }
      section.tech-skills {
        position: absolute;
        top: 144 * $unitSpacing;
        left: 8 * $unitSpacing;
        right: 8 * $unitSpacing;
      }
      section.other-skills {
        position: absolute;
        top: 184 * $unitSpacing;
        left: 8 * $unitSpacing;
        right: 8 * $unitSpacing;
      }
      section.interests {
        position: absolute;
        top: 232 * $unitSpacing;
        left: 8 * $unitSpacing;
        right: 8 * $unitSpacing;
      }
      section.website {
        position: absolute;
        top: 260 * $unitSpacing;
        left: 8 * $unitSpacing;
        right: 8 * $unitSpacing;
        div {
          background-color: lightgrey;
          text-align: center;
          a {
            font-family: "Roboto", sans-serif;
            text-decoration: none;
          }
        }
      }
    }
  }
}

@media print {
  section.cv-container {
    padding: 0;
    display: block;
    overflow: hidden;
    background-color: $pageColor;

    section.page {
      margin-bottom: 0;
    }
  }
}
