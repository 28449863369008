@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
body {
  background-color: lightgray;
  overflow: hidden;
  margin: 0;
}

img.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  object-position: 100%;
  /* Add the blur effect */
  filter: blur(3px);
  -webkit-filter: blur(3px);
}

section.home-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  font-family: "Ubuntu", sans-serif;
  color: darkslategray;
  background-color: rgba($color: #ffffff, $alpha: 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  section.profile-card-wrapper {
    /* Glassmorphism card effect */
    div.profile-card {
      backdrop-filter: blur(16px) saturate(180%);
      -webkit-backdrop-filter: blur(16px) saturate(180%);
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 12px;
      border: 1px solid rgba(209, 213, 219, 0.3);
      display: inline-block;
      padding: 16px;
      max-width: 600px;
      margin: 0 16px;
      div.pic-and-name {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        img.profile-pic {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          border: solid 2px #bbb;
        }
        h3 {
          display: inline-block;
          margin-left: 12px;
        }
      }
      p {
        text-align: left;
      }
      hr {
        height: 1px;
        background-color: #ccc;
        border: none;
      }
      div.resume-link-wrapper {
        padding-right: 12px;
        margin-bottom: 4px;
        text-align: end;
        a.resume-link {
          // https://simplestepscode.com/css-glass-button-tutorial/
          /* background styles */
          position: relative;
          display: inline-block;
          padding: 6px 12px;
          background-color: green; /*for compatibility with older browsers*/
          background-image: linear-gradient(green, lightgreen);

          /* text styles */
          text-decoration: none;
          color: #fff;
          border-radius: 3px;
          box-shadow: 0px 1px 4px -2px #333;
          text-shadow: 0px -1px #333;
          transition: ease 0.3s;

          &:after {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            width: calc(100% - 4px);
            height: 50%;
            background: linear-gradient(
              rgba(255, 255, 255, 0.8),
              rgba(255, 255, 255, 0.2)
            );
          }
          &:hover {
            background: linear-gradient(#073, #0fa);
          }
          &:active {
            transform: scale(0.96);
          }
        }
      }
    }
  }
  section.footer {
    margin: 16px;
    // border-top: solid 1px #ccc;
    text-align: center;
    // padding: 12px;
    span {
      margin: 0;
      display: inline-block;
      cursor: pointer;
      // https://simplestepscode.com/css-glass-button-tutorial/
      /* background styles */
      position: relative;
      display: inline-block;
      padding: 6px 12px;
      background-color: skyblue; /*for compatibility with older browsers*/
      background-image: linear-gradient(purple, skyblue);

      /* text styles */
      text-decoration: none;
      color: #fff;
      border-radius: 3px;
      box-shadow: 0px 1px 4px -2px #333;
      text-shadow: 0px -1px #333;
      transition: ease 0.3s;
      &:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: calc(100% - 4px);
        height: 50%;
        background: linear-gradient(
          rgba(255, 255, 255, 0.8),
          rgba(255, 255, 255, 0.2)
        );
      }
      &:hover {
        background: linear-gradient(rgb(114, 63, 114), rgb(134, 191, 214));
      }
      &:active {
        transform: scale(0.96);
      }
    }
  }
}

section.popup-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(218, 216, 223, 0.4);
  backdrop-filter: blur(4px);
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Ubuntu", sans-serif;
  color: darkslategray;
  div.popup-wrapper {
    div.popup {
      backdrop-filter: blur(16px) saturate(180%);
      -webkit-backdrop-filter: blur(16px) saturate(180%);
      background-color: rgba(255, 255, 255, 0.75);
      border-radius: 12px;
      border: 1px solid rgba(209, 213, 219, 0.3);
      display: inline-block;
      padding: 16px;
      max-width: 600px;
      margin: 0 16px;
      position: relative;
      a {
        text-decoration: none;
      }
      // https://codepen.io/jermbo/pen/eztFu
      .close-button {
        width: 36px;
        height: 36px;
        box-shadow: 0px 10 10px 10px rgba(0, 0, 0, 0.25);
        border-radius: 25px;
        backdrop-filter: blur(16px) saturate(180%);
        -webkit-backdrop-filter: blur(16px) saturate(180%);
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.75);
        border: 1px solid rgba(209, 213, 219, 0.3);
        // margin-top: -75px;
        // margin-left: -75px;
        position: absolute;
        right: -16px;
        top: -16px;
        // display: block;
        // z-index: 200;
        cursor: pointer;
        text-indent: -9999px;
        &:before,
        &:after {
          content: "";
          width: 55%;
          height: 2px;
          background: #fff;
          position: absolute;
          top: 48%;
          left: 22%;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          -webkit-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -ms-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }
        &:after {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          -webkit-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -ms-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }
        &:hover:before,
        &:hover:after {
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
  }
}
